import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

const HomeThree = lazy(() => import("./home/HomeThree"));

const App = () => {
  return (
    <Router>
      <Suspense fallback={<div />}>
        <Routes>
          <Route path="/" element={<HomeThree />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
